<template>
	<span v-if="(_num>0 && IsCanViewJieDu) || IsCanAddJieDu" :style="{display:_num<=0?'none':'inline-block'}" @click.stop="">
		<el-popover v-if="_num>0" placement="right" title="专家说" :width="600" trigger="hover" v-model:visible="isShow"
			@show="onShow" :show-after="500" >
			<template #reference>
				<span class="item hasNum">
					<span class="num">
						{{_num}}
					</span>
				</span>
			</template>
			<div style="overflow-y:auto;max-height: 90vh;padding-right: 20px;">
				<tagShow :refID="refID" :refType="refType" :list="list" @closePop="closePop" @afterSaved="afterSaved">
				</tagShow>
			</div>
		</el-popover>
		<span v-else-if="IsCanAddJieDu" class="item add" title="点击添加解读" @click.stop="add"></span>
		<el-dialog v-model="isShowEdit" title="新增解读" width="850px" :close-on-click-modal="true"
			:close-on-press-escape="true" append-to-body>
			<editContent :refType="refType" :refID="refID" @afterSaved="afterSaved" @afterCancled="afterCancled">
			</editContent>
		</el-dialog>
	</span>
</template>
<script>
	import editContent from "./cp_editContent.vue";
	import tagShow from "./cp_tagShow.vue";

	export default {
		emits: ['afterChanged'],
		components: {
			editContent,
			tagShow
		},
		props: {
			refID: {
				Type: String,
				default: ''
			},
			refType: {
				Type: Number,
				default: 0
			},
			num: {
				Type: Number,
				default: 0
			},
		},
		data() {
			return {
				isShow: false,
				isShowEdit: false,
				_num: 0,
				list: [],
				needLoad: true
			}
		},
		watch: {
			num() {
				this._num = this.num
			}
		},
		computed: {
			IsCanAddJieDu() {
				let isCan = this.$api.IsCanAddJieDu()
				return isCan
			},
			IsCanViewJieDu() {
				let isCan = this.$api.IsCanViewJieDu()
				return isCan
			},

			IsVirtualLogin() {
				let isVirtual = this.$store.getters.get_isVirtualLogin()
				return isVirtual
			},
			IsPeiXuan() {
				let IsPeiXuan = this.$api.IsPeiXuan()
				return IsPeiXuan
			}
		},
		created() {
			this._num = this.num
		},
		methods: {
			add() {
				this.isShowEdit = true
			},
			closePop() {
				this.isShow = false
				// alert(this.isShow)
			},
			afterSaved() {
				this.isShowEdit = false
				// this._num++
				this.needLoad = true
				this.GetContentListForObj()
			},
			afterCancled() {
				this.isShowEdit = false
			},
			onShow() {
				if (!this.needLoad)
					return
				this.GetContentListForObj()
			},
			GetContentListForObj() {
				this.$http.get('/tag/GetContentListForObj', {
					refID: this.refID,
					refType: this.refType
				}, false).then(res => {
					if (res.code == 1) {
						this.list = res.data
						this.needLoad = false
						this._num = this.list.length
					} else {
						this.$message.error(res.msg)
					}
				})
			}
		},
	}
</script>
<style scoped="scoped" lang="less">
	.item {
		background-image: url('/imgs/jiedu_num.png');
		background-repeat: no-repeat;
		// background-size: 100%;
		background-size: contain;
		color: #d34249;
		width: 20px;
		height: 20px;
		line-height: 20px;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		font-size: 14px;
		box-sizing: border-box;
		position: relative;
	}

	.num {
		position: absolute;
		display: inline-block;
		font-size: 10px;
		// background-color: #F56C6C;
		// background-color: #eee;
		border-radius: 50%;
		border: 1px solid #ddd;
		// color: white;
		color: #888;
		// left: calc(100% - 5px);
		right: -2px;
		top: -5px;
		width: 16px;
		height: 16px;
		line-height: 16px;
	}

	.hasNum {
		width: 32px;
	}

	.zero {
		display: none;
	}

	.add {
		font-size: 14px;
		cursor: pointer;
		background-image: url('/imgs/jiedu_add.png') !important;
		background-color: white;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
	}
</style>