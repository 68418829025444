/* 
自定义接口,请在此定义
 */
import {
	ElMessage
} from 'element-plus';
import http from '@/../src/api/http.js'
import store from '../store/index.js'

function CheckAuthority(page, callBack) {
	http.get('Base/CheckAuthority', {
		page
	}, true).then(rslt => {
		if (callBack != null)
			callBack(rslt)
	})
}

// 同步本地缓存的用户信息
function SynCacheInfo(callBack) {
	http.get("/Student/GetClientInfo", {}, true).then((res) => {
		if (res.code == 1) {
			let userInfo = res.data.UserInfo
			let xunKeType = res.data.XuanKeType
			let model = res.data.Model
			store.commit("setUserInfo", userInfo)
			store.commit("set_XuanKeType", xunKeType)
			store.commit("set_GKModel", model)
			let obj = {}
			obj.AreaID = userInfo.AreaID
			obj.AreaName = userInfo.AreaName
			store.commit("set_UserSetArea", obj)
			store.commit("set_StuTags", res.data.Tags);
		} else {
			ElMessage.error(res.msg)
		}
		if (callBack != null)
			callBack()
	})
}

function IsCanViewJieDu() {
	let isCan = false
	let isVirtual = store.getters.get_isVirtualLogin()
	if (isVirtual || this.IsPeiXuan())
		isCan = true
	return isCan
}

function IsCanAddJieDu() {
	let isCan = false
	let isVirtual = store.getters.get_isVirtualLogin()
	// let isZiYing = store.getters.get_IsZiYing()
	// //虚拟登录，并且具有自营机构的助理，才可添加解读。通过角色权限比较彻底，此处暂时这样改。
	// if (isVirtual && isZiYing) {
	// 	isCan = true
	// }
	if(isVirtual)
		isCan=true
	return isCan
}
//是否为家庭培训版用户
function IsPeiXuan() {
	let val = 0
	let uInfo = store.getters.getUserInfo()
	if (uInfo) {
		val = uInfo.IsPeixun
	}
	return val
}





export default {
	CheckAuthority,
	SynCacheInfo,
	IsCanAddJieDu,
	IsCanViewJieDu,
	IsPeiXuan
}