import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import store from '../store/index'
import globle from '../util/globle'
import http from '../api/http.js'
import api from '../api/api.js'

const routes = [{
		path: '/',
		name: 'layout',
		redirect: '/home',
		component: () => import('@/views/Layout.vue'),
		children: [{
				path: '/home',
				name: 'home',
				component: () => import('@/views/home/home.vue'),
				meta: {
					anonymous: true,
					KeepAlive: true,
				}
			},
			{
				path: '/artsport',
				name: 'artsport',
				component: () => import('@/views/artSport/index.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/mygift',
				name: 'mygift',
				component: () => import('@/views/gift/mygifthistory.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/majorFirst',
				name: 'majorFirst',
				component: () => import('@/views/major/major_first.vue'),
				meta: {
					anonymous: true,
					KeepAlive: true,
				}
			},
			{
				path: '/majorSecond',
				name: 'majorSecond',
				component: () => import('@/views/major/major_second.vue'),
				meta: {
					anonymous: true
				}
			},
			//填志愿时用
			{
				path: '/school',
				name: 'school',
				component: () => import('@/views/plan/school.vue'),
				meta: {
					anonymous: true,
					KeepAlive: true,
				}
			},
			{
				path: '/schoolFirst',
				name: 'schoolFirst',
				component: () => import('@/views/school/school_first.vue'),
				meta: {
					anonymous: true,
					KeepAlive: true,
				}
			},
			{
				path: '/schoolMap',
				name: 'schoolMap',
				component: () => import('@/views/school/school_map.vue'),
				meta: {
					anonymous: true,
					KeepAlive: true,
				}
			},
			{
				path: '/schoolSecond',
				name: 'schoolSecond',
				component: () => import('@/views/school/school_second.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/record',
				name: 'record',
				component: () => import('@/views/record/record.vue'),
				meta: {
					anonymous: true,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/plan',
				name: 'plan',
				component: () => import('@/views/plan/plan2.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/score',
				name: 'score',
				component: () => import('@/views/score/score.vue'),
				meta: {
					anonymous: true,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/fangan',
				name: 'fangan',
				component: () => import('@/views/score/score.vue'),
				meta: {
					anonymous: true,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/score1',
				name: 'score1',
				component: () => import('@/views/score/PlanSearcher.vue'),
				meta: {
					anonymous: true,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/score2',
				name: 'score2',
				component: () => import('@/views/score/score2.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/score3',
				name: 'score3',
				component: () => import('@/views/score/score3.vue'),
				meta: {
					anonymous: true,
					needLogin: false
				}
			},
			{
				path: '/score4',
				name: 'score4',
				component: () => import('@/views/score/score4.vue'),
				meta: {
					anonymous: true,
					needLogin: false
				}
			},
			{
				path: '/score5',
				name: 'score5',
				component: () => import('@/views/score/score5.vue'),
				meta: {
					anonymous: true,
					needLogin: false
				}
			},
			{
				path: '/subject1',
				name: 'subject1',
				component: () => import('@/views/subject/subject1.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/subject2',
				name: 'subject2',
				component: () => import('@/views/subject/subject2.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/subject3',
				name: 'subject3',
				component: () => import('@/views/subject/subject3.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/cata',
				name: 'cata',
				component: () => import('@/views/new/cata.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/detail',
				name: 'detail',
				component: () => import('@/views/new/detail.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/base',
				name: 'base',
				component: () => import('@/views/person/base.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/orgServer',
				name: '/orgServer',
				component: () => import('@/views/person/orgServer.vue'),
				meta: {
					needLogin: false,
				}
			},
			{
				path: '/pass',
				name: 'pass',
				component: () => import('@/views/person/pass.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/phone',
				name: 'phone',
				component: () => import('@/views/person/phone.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/logout',
				name: 'logout',
				component: () => import('@/views/person/logout.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/webSite',
				name: 'webSite',
				component: () => import('@/views/new/webSite.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/orgView',
				name: 'orgView',
				component: () => import('@/views/template/orgView.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/contentlist',
				name: 'contentlist',
				component: () => import('@/views/new/contentlist.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/contentlist2',
				name: 'contentlist2',
				component: () => import('@/views/new2/contentlist.vue'),
				meta: {
					anonymous: true
				}
			},
			// {
			// 	path: '/mbti',
			// 	name: 'mbti',
			// 	component: () => import('@/views/Interest/MBTI.vue'),
			// 	meta: {
			// 		anonymous: true,
			// 		needLogin: true
			// 	}
			// },
			// {
			// 	path: '/holland',
			// 	name: 'holland',
			// 	component: () => import('@/views/Interest/Holland.vue'),
			// 	meta: {
			// 		anonymous: true,
			// 		needLogin: true
			// 	}
			// },
			{
				path: '/Occupation',
				name: 'Occupation',
				component: () => import('@/views/Occupation/index.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/test',
				name: 'test',
				component: () => import('@/views/testapi/test.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/orderinfo',
				name: 'orderinfo',
				component: () => import('@/views/person/orderinfo.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/orderlist',
				name: 'orderlist',
				component: () => import('@/views/person/orderlist.vue'),
				meta: {
					needLogin: true
				}
			},
			{
				path: '/xieyi',
				name: 'xieyi',
				component: () => import('@/views/person/xieyi.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/msg',
				name: 'msg',
				component: () => import('@/views/message/msg.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/expert',
				name: 'expert',
				component: () => import('@/views/person/expert.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/schoolsubject',
				name: 'schoolsubject',
				component: () => import('@/views/subject/subject1.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/majorsubject',
				name: 'majorsubject',
				component: () => import('@/views/subject/subject3.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/totalsubject',
				name: 'totalsubject',
				component: () => import('@/views/subject/subject2.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/xuanke',
				name: 'xuanke',
				component: () => import('@/views/xuanke/index.vue'),
				meta: {
					anonymous: true,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/ceping',
				name: 'ceping',
				component: () => import('@/views/paper/index.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/cpdetail',
				name: 'cpdetail',
				component: () => import('@/views/paper/detail.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/zhiNan',
				name: 'zhiNan',
				component: () => import('@/views/person/zhiNan.vue'),
				meta: {
					anonymous: true,
					needLogin: false
				}
			},
			{
				path: '/hotarea',
				name: 'hotarea',
				component: () => import('@/views/hotArea/index.vue'),
				meta: {
					anonymous: true,
					needLogin: true
				}
			},
			{
				path: '/expertor',
				name: 'expertor',
				component: () => import('@/views/expertor/list.vue'),
				meta: {
					anonymous: false,
					needLogin: false
				}
			},
			{
				path: '/expertor2',
				name: 'expertor2',
				component: () => import('@/views/expertor/list2.vue'),
				meta: {
					anonymous: false,
					needLogin: false
				}
			},
			{
				path: '/expertordetail2',
				name: 'expertordetail2',
				component: () => import('@/views/expertor/detail2.vue'),
				meta: {
					anonymous: false,
					needLogin: false
				}
			},
			{
				path: '/expertordetail',
				name: 'expertordetail',
				component: () => import('@/views/expertor/detail.vue'),
				meta: {
					anonymous: false,
					needLogin: false
				}
			},
			{
				path: '/expertorProduct',
				name: 'expertorProduct',
				component: () => import('@/views/expertor/product.vue'),
				meta: {
					anonymous: false,
					needLogin: false
				}
			},
			{
				path: '/server',
				name: 'server',
				component: () => import('@/views/person/server.vue'),
				meta: {
					anonymous: false,
					needLogin: true
				}
			},
			{
				path: '/jiangZuo',
				name: 'jiangZuo',
				component: () => import('@/views/person/jiangZuo.vue'),
				meta: {
					anonymous: false,
					needLogin: true
				}
			},
			{
				path: '/buy',
				name: 'buy',
				component: () => import('@/views/buy/index.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/course',
				name: 'course',
				component: () => import('@/views/study/index.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/lesson',
				name: 'lesson',
				component: () => import('@/views/study/lesson.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/tifen',
				name: 'tifen',
				component: () => import('@/views/tifen/index.vue'),
				meta: {
					anonymous: true,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/lessons',
				name: 'lessons',
				component: () => import('@/views/tifen/lesson.vue'),
				meta: {
					anonymous: true
				}
			},
			{
				path: '/authorityInfo',
				name: 'authorityInfo',
				component: () => import('@/views/authority/authorityInfo.vue'),
				meta: {
					anonymous: false
				}
			},
			{
				path: '/product',
				name: 'product',
				component: () => import('@/views/product/buy.vue'),
				meta: {
					needLogin: true,
				}
			},
			{
				path: '/firstbuy',
				name: 'firstbuy',
				component: () => import('@/views/product/product.vue'),
				meta: {
					needLogin: false,
				}
			},
			{
				path: '/productlist',
				name: 'productlist',
				component: () => import('@/views/product/productlist.vue'),
				meta: {
					needLogin: false,
				}
			},
			{
				path: '/zhaosheng',
				name: 'zhaosheng',
				component: () => import('@/views/zhaosheng/index.vue'),
				meta: {
					needLogin: false,
				}
			},
			{
				path: '/zhaosheng/details',
				name: '/zhaosheng/details',
				component: () => import('@/views/zhaosheng/details.vue'),
				meta: {
					needLogin: false,
				}
			},
			{
				path: '/stuzhiyuan/index',
				name: 'stuzhiyuan/index',
				component: () => import('@/views/stuZhiYuan/index.vue'),
				meta: {
					anonymous: false,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/stuzhiyuan/plan',
				name: 'stuzhiyuan/plan',
				component: () => import('@/views/stuZhiYuan/plan.vue'),
				meta: {
					anonymous: false,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/stuzhiyuan/zhiyuan',
				name: 'stuzhiyuan/zhiyuan',
				component: () => import('@/views/stuZhiYuan/zhiyuan.vue'),
				meta: {
					anonymous: false,
					needLogin: true,
					needAuthority: true
				}
			},
			{
				path: '/stuzhiyuan/autoZhiYuan',
				name: 'stuzhiyuan/autoZhiYuan',
				component: () => import('@/views/stuZhiYuan/autoZhiYuan.vue'),
				meta: {
					anonymous: false,
					needLogin: true,
					needAuthority: true
				}
			},
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/redirect/404.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/401',
		name: '401',
		component: () => import('@/views/redirect/401.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/redirect/index.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/OrgEnabled',
		name: 'OrgEnabled',
		component: () => import('@/views/redirect/OrgEnabled.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/virtualLogin',
		name: 'virtualLogin',
		component: () => import('@/views/account/virtualLogin.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/timeline',
		name: 'timeline',
		component: () => import('@/views/new/gk_timeLine.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/tool/map',
		name: 'map',
		component: () => import('@/views/tool/map.vue'),
		meta: {
			needLogin: false,
		}
	},
	{
		path: '/openLogin',
		name: 'openLogin',
		component: () => import('@/views/account/openLogin.vue'),
		meta: {
			anonymous: true
		}
	},
	{
		path: '/orgpub',
		name: 'orgpub',
		component: () => import('@/views/organization/orgpub.vue'),
		meta: {
			anonymous: true
		}
	},
]

const router = createRouter({
	history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
	routes
})

function getQueryString(url, name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url, name) || [, ""])[1]
		.replace(/\+/g, '%20')) || null
}

router.beforeEach((to, from, next) => {

	if (from.path == "/home" && to.path == "/logout") {
		store.commit("clearUserInfo");
		store.commit("set_isVirtualLogin", false);
		router.go(0);
		return;
	}

	if (to.path == "/logout") {
		store.commit("clearUserInfo");
		store.commit("set_isVirtualLogin", false);
		//强制刷新
		router.go(0);
		return;
	}

	if (to.path == "/virtualLogin" || to.path == "/openLogin") {
		store.commit("clearUserInfo");
	}
	if (to.path == "/virtualLogin") {
		store.commit("set_isVirtualLogin", false);
	}
	// debugger
	//信息不完善，跳到“基本信息”页面	
	var user = store.getters.getUserInfo()
	// if (to.name != 'base' && user != null &&
	// 	(user.Mobile == '' || (user.CardType > 0 && user.CardType != globle.CARDTYPE_SCHCARD &&
	// 		(store.getters.getGKModel() != '老高考' && (user.Sbj == '' || user.WLType == '') || store.getters
	// 			.getGKModel() == '老高考' && user.WLType == '')))) {
	// 	store.commit('set_currentUrl', "/base");
	// 	return next({
	// 		path: '/base'
	// 	});
	// }

	if (to.name != 'base' && user != null && (user.Mobile == '' || user.Sex == '')) {
		//手机号填写后才能访问其它页面
		store.commit('set_currentUrl', "/base");
		return next({
			path: '/base'
		});
	}

	if (to.name == 'score' || to.name == 'score1' || to.name == 'score2' || to.name == 'fangan' || to.name ==
		'record') {
		//录取分查询、做方案等页面，必须完善选科或文理
		if (user != null && user.CardType > 0 && user.CardType != globle.CARDTYPE_SCHCARD && user.WLType ==
			'') {
			store.commit('set_showCheckSbj', false);
			setTimeout(function() {
				store.commit('set_showCheckSbj', true);
			}, 100);
			// vue.$nextTick(() => {
			// 	store.commit('set_showCheckSbj', true);
			// });
			return;
		}
	}
	if (to.meta.needLogin) {
		if (store.getters.getToken().length <= 0) {
			store.commit("set_loginToPath", to.path);
			let orgExtModel = store.getters.getOrgExtModel()
			if (orgExtModel != null && orgExtModel.PcBackUrl) {
				let tourl = encodeURIComponent(window.location.href)
				let backUrl = orgExtModel.PcBackUrl
				backUrl = backUrl.trim()
				if (backUrl.indexOf('?') > -1)
					backUrl += '&tourl=' + tourl
				else
					backUrl += '?tourl=' + tourl
				window.location.href = backUrl
			} else
				router.push(from.path + "?needLogin=true&v=" + parseInt(new Date().getTime() / 1000));
			return;
		}
	}
	//对特殊模块检查授权
	if (to.meta.needAuthority) {

		api.CheckAuthority(to.path, rslt => {
			if (rslt.code == 1) {
				if (rslt.data.IsCan) {
					return next()
				} else {
					let remark = encodeURIComponent(rslt.data.Remark)
					let toUrl = encodeURIComponent(to.fullPath)

					if (rslt.data.pageCode == 2) {
						router.push('/orgServer')
					} else {
						router.push('/product?tocard=' + rslt.data.ToCard + '&toUrl=' + toUrl)
					}
					//router.push('authorityInfo?toCard=' + rslt.data.ToCard + '&remark=' + remark +'&toUrl=' + toUrl);
				}
			} else {
				alert(rslt.msg)
			}
		})
		return
	}

	store.commit('set_currentUrl', to.path);
	// if (getQueryString(to.fullPath, "clear")) {
	// 	store.commit("clearUserInfo");
	// 	return next({
	// 		path: '/login'
	// 	});
	// }

	// 找不到页面
	if (to.matched.length == 0) {
		return next({
			path: '/404'
		});
	}
	//没有权限
	// if (to.path != '/index' && to.path != '/' && to.path != '/401' && to.path != '/404' && to.path
	// 	.toLowerCase() != '/login') {
	// 	if (store.getters.getMenuPermission(to.path)) {
	// 		return next({
	// 			path: '/401'
	// 		});
	// 	}
	// }
	// store.dispatch("onLoading", true);
	return next();
})

router.afterEach((to, from) => {
	// store.dispatch("onLoading", false);
})

export default router