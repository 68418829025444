<template>
	<div class="filter">
		<el-row v-if="xuankeToMajor && xuanke" class="type" :gutter="0">
			<el-col class="leftCol cj_lightBack" :span="4">
				<div class="title">选科</div>
			</el-col>
			<el-col class="rightCol" :span="20">
				<div class="content">
					<XuanKe :AreaID='userInfo.AreaID' :XuanKeType='XuanKeType' :AllSbjList='subjects'
						@change='getschool()'>
					</XuanKe>
				</div>
			</el-col>
		</el-row>

		<el-row v-if="xuanke || showXuanKeYear" class="type" :gutter="0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">选科要求年份<span style="color: red;">*</span></div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<el-select v-model="xuankeYear" placeholder="请选择年份" @change="getschool()">
						<el-option v-for="year in xuankeYears" :key="year" :label="year" :value="year">
						</el-option>
					</el-select>
				</div>
			</el-col>
		</el-row>

		<el-row v-if="score" class="type" :gutter="0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">录取分查询</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div>
					<el-radio-group v-model="by" style="padding-left: 20px;">
						<el-radio :label="-1" title="分数、排名不做为筛选条件" @click="this.IsHideScore = true;"
							class="noLimit">不限</el-radio>
						<el-radio :label="0" title="按录入的分数范围搜索" @click="this.IsHideScore = false;">按分数</el-radio>
						<el-radio :label="1" title="按录入的排名范围搜索" @click="this.IsHideScore = false;">按排名</el-radio>
					</el-radio-group>
				</div>
				<div class="content" style="display: flex;">
					<span>
						<div style="margin-bottom: 10px;" v-if="stuScore && !IsFen">等效分数：<span
								style="color: var(--el-input-font-color,var(--el-text-color-regular));">{{ MyScore +
									'分' }}</span>
						</div>
						<div>
							分数：&nbsp;
							<el-input @change="changeScore(1)" v-model="startvalue" style="width:75px;" type="number"
								:disabled="this.IsHideScore" />
							分&nbsp;
							<span class="cj_color">--</span>&nbsp;
							<el-input @change="changeScore(2)" v-model="endvalue" style="width: 75px;" type="number"
								:disabled="this.IsHideScore" />
							分&nbsp;
						</div>
					</span>
					<span style="margin-left: 20px;">
						<div style="margin-bottom: 10px;" v-if="stuScore && !IsFen">等效排名：<span
								style="color: var(--el-input-font-color,var(--el-text-color-regular));">
								<el-tooltip class="box-item" effect="light" content="" placement="top-start">
									<template #content>
										<div style="max-width: 600px; color: #E6A23C;">
											<p>等效排名</p>
											<p style="padding-left:20px;">
												推算排名根据往年全省成绩分布进行测算，通过大数据模型对数据进行了合理修正。<br>如果与心理预期有差距，登记时可以选择自填排名。</p>
										</div>
									</template>
									{{ MyOrder + '名' }}
								</el-tooltip></span>
						</div>
						<div>
							排名：&nbsp;
							<el-input @change="changeOrder(false)" v-model="startTemp" style="width: 100px;"
								type="number" :disabled="this.IsHideScore" />
							名&nbsp;
							<span class="cj_color">--</span>&nbsp;
							<el-input @change="changeOrder(true)" v-model="endTemp" style="width: 100px;" type="number"
								:disabled="this.IsHideScore" />
							名
						</div>
					</span>
					<span style="display: flex;" v-if="false">
						<div v-if="stuScore" style="margin-bottom: 10px;">&nbsp;</div>
						<el-button @click="clearScore" style="margin-left: 20px;" type="primary">清除</el-button>

						<el-button @click="clearAll" style="margin-left: 20px;" type="danger">清除所有条件</el-button>
					</span>
				</div>

			</el-col>
		</el-row>
		<el-row v-if="score || showSchMajor" class="type" :gutter="0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">院校专业</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<el-row v-if="(search && !xuankeToMajor) || IsShowMajor" class="type" :gutter="0">
						<el-col :span="24">
							<el-button icon="Plus" type="primary" @click="ShowMajors">添加包含专业</el-button>
							<el-button icon="Close" @click="removeAllMajor">清空包含专业</el-button>
							<!-- <el-button type="primary" style="margin-left: 10px;" @click="getschool">搜索</el-button> -->
							<div style="margin-top: 10px;">
								<template v-for="item in MajorIDs">
									<el-button size="mini" icon="Close" @click="removemajor(item)">
										{{ item.split('|')[1] }}
									</el-button>
								</template>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="8">
							<span style="">院校名：</span>
							<el-input v-model="schname" style="width:80%" placeholder="请输入院校名" clearable></el-input>
						</el-col>
						<el-col :span="10">
							<span style="margin-left: 20px;">专业名：</span>
							<el-input style="width:80%" v-model="majorname" clearable @keyup.enter="getschool()"
								placeholder="请输入专业名，多个关键字请使用逗号或空格分隔">
							</el-input>
						</el-col>
						<el-col :span="6">
							<el-button type="primary" style="margin-left: 10px;" @keyup.enter="getschool(true)"
								@click="getschool(true)">搜索</el-button>
							<el-button icon="Close" @click="clearAll" title="清空当前页面已选的所有筛选条件">清空所有条件</el-button>
						</el-col>
					</el-row>

				</div>
			</el-col>
		</el-row>
		<el-row class="type" :gutter="0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">院校省份</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content width_content">
					<el-checkbox-group v-model="Province" @change="areaChange">
						<el-checkbox v-for="item in provincelist" :label="item.ID"
							:class="item.ID == '0' ? 'noLimit' : ''" :title="item.ID == '0' ? '院校所在省份不做为筛选条件' : ''">{{
								item.Name }}</el-checkbox>
					</el-checkbox-group>
				</div>
			</el-col>
		</el-row>
		<el-row class="type" :gutter="0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">院校类型</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content width_content" style="padding-right:0px;">
					<el-checkbox-group v-model="SchType" @change="schTypeChange">
						<el-checkbox size="default" v-for="item in schooltypelist" :label="item.Code"
							:class="item.Code == '0' ? 'noLimit' : ''" :title="item.Code == '0' ? '院校类型不做为筛选条件' : ''">
							{{ item.Name }}
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</el-col>
		</el-row>
		<el-row class="type" :gutter="0">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">院校层次</div>
			</el-col>
			<el-col :span="20" class="rightCol">
				<div class="content">
					<el-radio v-for="(item, n) in SchLevel" v-model="level" name="level" :label="item.Code"
						@change="getschool()">{{ item.Name }}</el-radio>
					<!-- 批次 -->
					<el-select v-if="picis && ShowPc && score" v-model="moBanPiCiID" :disabled="!piciEnable"
						placeholder="请选择批次" size="default">
						<el-option label="所有批次" value="">
						</el-option>
						<el-option v-for="item in picis" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
					<el-divider style="margin: 5px 0px;" />
					<el-checkbox v-model="IsSchTagNoLimit" @change="schTagChange" name='schTagNoLimit' class="noLimit"
						checked="true" title="公办、民办、985、211等院校层次不做为筛选条件">不限</el-checkbox>
					<el-checkbox v-for="(item, index) in SchFoundType" v-model="foundtype" name="type"
						:label="item.Code" @change="schTagChange">
						{{ item.Name }}
					</el-checkbox>
					<span style="color: #cdcdcd;">|</span>&nbsp;&nbsp;&nbsp;&nbsp;
					<el-checkbox v-model="Is985" @change="schTagChange">985</el-checkbox>
					<el-checkbox v-model="Is211" @change="schTagChange">211</el-checkbox>
					<el-checkbox v-model="IsXiao211" @change="schTagChange">小211</el-checkbox>
					<el-checkbox v-model="IsGraduateSch" @change="schTagChange">研究生院</el-checkbox>
					<el-checkbox true-label="1" false-label="0" v-model="yiliu" @change="schTagChange">“双一流”高校
					</el-checkbox>

				</div>
			</el-col>
		</el-row>

		<el-row v-if="search && !xuanke && !showSchMajor" class="type">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">院校直达</div>
			</el-col>
			<el-col :span="20" style="padding: 5px 0;" class="rightCol">
				<div class="content">
					<el-input style="width: 300px;" placeholder="请输入院校名" v-model="keyword" clearable>
					</el-input>
					<el-button type="primary" style="margin-left: 10px;" @click="getschool(true)">搜索</el-button>
				</div>
			</el-col>
		</el-row>

		<el-row v-if="score" class="type">
			<el-col :span="4" class="leftCol cj_lightBack">
				<div class="title">招生方式</div>
			</el-col>
			<el-col :span="20" style="padding: 5px 0;" class="rightCol">
				<div class="content">
					<el-select v-model="ZkType" placeholder="请选择招生方式,可多选" style="width: 500px" multiple clearable>
						<el-option v-for="(item, n) in ZKTypeList" :key="item.Code" :label="item.Name"
							:value="item.Code"></el-option>
					</el-select>
				</div>
			</el-col>
		</el-row>
		<el-dialog v-model="major" title="请选择专业" width="1000px" top="5vh">
			<majorSelect ref="majorSelect" @majorConfirm="majorConfirm"></majorSelect>
			<!-- <template #footer>
				<span class="dialog-footer">
					<el-button @click="major = false">取消</el-button>
					<el-button type="primary" @click="majorConfirm2">确认</el-button>
				</span>
			</template> -->
		</el-dialog>
	</div>
</template>

<script>
import XuanKe from '@/components/cp_XuanKe.vue'
export default {
	components: {
		XuanKe
	},
	props: {
		//search 高校库过来，score 录取分数查询，xuanke 大学选科查询
		search: {
			type: Boolean,
			default: false
		},
		syiliu: {
			type: Number,
			default: 0
		},
		score: {
			type: Boolean,
			default: false
		},
		xuanke: {
			type: Boolean,
			default: false
		},
		xuankeToMajor: {
			type: Boolean,
			default: false
		},
		//是否显示专业选择区
		IsShowMajor: {
			type: Boolean,
			default: false
		},
		ShowPc: {
			type: Boolean,
			default: true
		},
		showSchMajor: {
			type: Boolean,
			default: false
		},
		showXuanKeYear: {
			type: Boolean,
			default: false
		},
		//文理类型，只在校园版指定按定义的任务文理类型填报 2023-04-28 lqw
		WLType: {
			type: String,
			default: ""
		},
		IsFen: {
			type: Boolean,
			default: false
		},
		//省份
		AreaID: {
			type: String,
			default: "",
			required: false,
		},
		MobanPiciModel: {
			type: Object,
			default: {}
		},
	},
	data() {
		return {
			major: false,
			startvalue: '',
			endvalue: '',
			startTemp: '',
			endTemp: '',
			stuScore: null,
			schname: '',
			majorname: '',
			by: -1, //-1不限，0分数，1排名
			provincelist: [],
			schooltypelist: [],
			Province: ['0'],
			SchType: ['0'],
			Is985: false,
			Is211: false,
			IsXiao211: false,
			IsGraduateSch: false,
			yiliu: 0,
			keyword: '',
			level: '106',
			foundtype: [], //103公立，104民办，105中外合作办学
			MajorIDs: [],
			xuankeYears: [],
			xuankeYear: '',
			subjects: [],
			SchLevel: [],
			SchFoundType: [],
			picis: [],
			moBanPiCiID: '',
			userInfo: this.$store.getters.getUserInfo(),
			PageKey: '',
			MyScore: '',
			MyOrder: '',
			ZKTypeList: [],
			ZkType: [],
			XuanKeType: '',
			piciEnable: true,
			IsHideScore: true,
			IsSchTagNoLimit: true,
			tm: null,

		}
	},
	watch: {
		MajorIDs() {
			this.setCacheMajors();
		},
		stuScore() {
			this.setCacheStuScore(this.stuScore);
		},
		xuankeYear() {
			this.setCacheXuanKeYear();
		},
		subjects() {
			this.setCacheSubjects();
		},
		Is211() {
			this.setCacheIs211();
		},
		IsXiao211() {
			this.setCacheIsXiao211();
		},
		yiliu() {
			this.setCacheyiliu();
		},
		Is985() {
			this.setCacheIs985();
		},
		foundtype() {
			this.setCacheFoundtype();
		},
		MobanPiciModel() {
			this.initFilter();
		},
		moBanPiCiID(newVal) {
			let is107 = false;
			for (let i = 0; i < this.picis.length; i++) {
				let pici = this.picis[i];
				if (newVal == pici.ID) {
					if (pici.Name.indexOf('专科') != -1 || pici.Name.indexOf('二段') != -1)
						is107 = true;
					break;
				}
			}
			if (newVal)
				this.level = is107 ? '107' : '106';
			this.setCacheMoBanPiCiID();
			if (this.piciEnable) {
				this.getschool();
			}
		},
		level() {
			this.setCacheLevel();
		},
		// Province() {
		// 	this.setCacheProvince();
		// },
		schname() {
			this.setCacheSchoolName();
		},
		majorname() {
			this.setCacheMajorName();
		},
		SchType() {
			this.setCacheSchType();
		},
		ZkType() {
			this.setCacheZkType();
		}
	},
	methods: {
		//监听回车按键
		keyDown(e) {
			if (e.keyCode === 13 || e.keyCode === 100) {
				this.getschool();
			}
		},
		ShowMajors() {
			this.major = true;
			this.$nextTick(function () {
				this.$refs["majorSelect"].setMajorids(this.MajorIDs);
			});
		},
		clearScore() {
			this.startvalue = '';
			this.endvalue = '';
			this.startTemp = '';
			this.endTemp = '';
			this.getschool();
			this.setCacheStuScore(null);
		},
		changeScore(type, resolve, reject) {
			let score = type == 1 ? this.startvalue : this.endvalue;
			let _this_ = this;
			this.GetOrder(score, function (data) {
				if (_this_.startvalue > 0) {
					if (type == 1) {
						_this_.startTemp = data;
					} else {
						_this_.endTemp = data;
					}
				}
				resolve(data);
			});
		},
		GetOrder(val, callback) {
			let obj = {
				ScoreType: this.MobanPiciModel.ScoreType,
				Score: val,
				stuID: this.StuID,
			};
			this.$http.get("/Score/GetGkOrder", obj).then((res) => {
				if (res.code == 1) {
					callback(res.data);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		changeOrder(isLOrder) {
			var iOrder = isLOrder ? this.endTemp : this.startTemp
			this.$http.get("/Score/GetScore", {
				order: iOrder
			}).then((res) => {
				if (res.code == 1) {
					if (isLOrder) {
						this.startvalue = res.data;
					} else {
						this.endvalue = res.data;
					}
				} else {
					// this.$message.error(res.msg);
				}
			});
		},
		getPicis() {
			return new Promise((resolve, reject) => {
				this.$http.get("/PiCi/GetMoBanPiciList").then((res) => {
					if (res.code == 1) {
						this.picis = res.data;
						let has = false;
						for (let i = 0; i < this.picis.length; i++) {
							if (this.moBanPiCiID == this.picis[i].ID) {
								has = true;
							}
						}
						if (!has) {
							this.moBanPiCiID = "";
							this.setCacheMoBanPiCiID();
						}
						resolve(res.code);
					} else {
						this.$message.error(res.msg);
						reject(res.code);
					}
				});
			});
		},
		removeAllMajor() {
			this.MajorIDs = [];
			if (this.$refs["majorSelect"]) {
				this.$refs["majorSelect"].setMajorids([], true);
			}
			this.ChangeMajorNames();
			this.$nextTick(() => {
				this.getschool();
			})
			this.setCacheMajors();
		},
		removemajor(data) {
			this.MajorIDs.filter(item => {
				if (item == data) {
					this.MajorIDs.splice(this.MajorIDs.indexOf(data), 1);
					if (this.$refs["majorSelect"]) {
						this.$refs["majorSelect"].setMajorids(this.MajorIDs);
					}
				}
			});
			this.ChangeMajorNames();
			this.$nextTick(() => {
				this.getschool();
			})
			this.setCacheMajors();
		},
		// majorConfirm2() {
		// 	this.MajorIDs = this.$refs.majorSelect.majorids;
		// 	this.major = false;
		// 	this.getschool();
		// },
		majorConfirm(data) {
			this.MajorIDs = data;
			this.major = false;
			this.ChangeMajorNames();
			this.$nextTick(() => {
				this.getschool();
			})
			this.setCacheMajors();
		},
		//专业选项更改后更改专业输入框
		ChangeMajorNames() {
			// let majorname = "";
			// this.MajorIDs.forEach(element => {
			// 	var mjname = element.split("|")[1];
			// 	if (mjname.indexOf("(") != -1) {
			// 		mjname = mjname.substring(0, mjname.indexOf("("));
			// 	}
			// 	majorname += "," + mjname
			// });
			// if (majorname.length > 0) {
			// 	this.majorname = majorname.substring(1);
			// } else {
			// 	this.majorname = "";
			// }
		},
		joinmajorids() {
			let str = "";
			for (var i = 0; i < this.MajorIDs.length; i++) {
				let item = this.MajorIDs[i];
				str += item.split('|')[0] + ',';
			}
			return str.substring(0, str.length - 1);
		},
		joinmajornames() {
			let str = this.majorname;

			for (var i = 0; i < this.MajorIDs.length; i++) {
				let item = this.MajorIDs[i];
				if (i == 0) {
					if (str && str != '') {
						str += ","
					}
				} else {
					str += ","
				}
				str += item.split('|')[1].replace('(大类招生)', '');
			}
			return str;
		},
		areaChange() {
			//各省份选项 与不限选项互斥
			if (this.Province.length == 0) {
				this.Province.push("0");
				return;
			}
			let noLimitIdx = this.Province.indexOf("0");
			if (noLimitIdx == 0 && this.Province.length > 1) {
				this.Province.splice(0, 1);
			} else if (noLimitIdx > 0) {
				this.Province = [];
				this.Province.push("0");
			}

			this.setCacheProvince();
			this.getschool();
		},
		schTypeChange() {
			//理工、财经、政法等院校类型与 不限 选项互斥
			if (this.SchType.length == 0) {
				this.SchType.push("0");
				return;
			}
			let noLimitIdx = this.SchType.indexOf("0");
			if (noLimitIdx == 0 && this.SchType.length > 1) {
				this.SchType.splice(0, 1);
			} else if (noLimitIdx > 0) {
				this.SchType = [];
				this.SchType.push("0");
			}
			this.getschool();
		},
		schTagChange(item, obj) {
			//公办、民办、985、211等于 不限 选项互斥
			if (this.IsSchTagNoLimit == true && obj.target.name == 'schTagNoLimit') {
				this.foundtype = [];
				this.Is985 = false;
				this.Is211 = false;
				this.IsXiao211 = false;
				this.IsGraduateSch = false;
				this.yiliu = 0;
			} else if (this.foundtype.length == 0 && this.Is985 == false && this.Is211 == false &&
				this.IsXiao211 == false && this.IsGraduateSch == false && this.yiliu == 0) {
				this.IsSchTagNoLimit = true;
			} else {
				this.IsSchTagNoLimit = false;
			}
			this.getschool();
		},
		getAreaIdsStr() {
			return this.Province.length == 1 && this.Province[0] == "0" ? '' : this.Province.join(',');
		},
		getSchTypeStr() {
			return this.SchType.length == 1 && this.SchType[0] == "0" ? '' : this.SchType.join(',');
		},
		getschool(isImmediate) {
			if (this.tm) {
				clearTimeout(this.tm)
				this.tm = null
			}
			if (isImmediate)
				this.getschoolExc()
			else
				this.tm = setTimeout(this.getschoolExc, 1000)
		},
		getschoolExc() {
			if (this.score) {
				this.$emit("getListWithScore", {
					by: this.by,
					startvalue: this.by == "0" ? this.startvalue : this.startTemp,
					endvalue: this.by == "0" ? this.endvalue : this.endTemp,
					schname: this.schname,
					majorname: this.joinmajornames(),
					schooltypes: this.getSchTypeStr(),
					areaids: this.getAreaIdsStr(),
					level: this.level,
					is211: this.Is211 ? 1 : 0,
					is985: this.Is985 ? 1 : 0,
					isxiao211: this.IsXiao211 ? 1 : 0,
					isgraduate: this.IsGraduateSch ? 1 : 0,
					foundtype: this.foundtype.join(','),
					yiliu: this.yiliu,
					moBanPiCiID: this.moBanPiCiID,
					zkType: this.ZkType.join(',')
				});
			} else if (this.xuanke && !this.xuankeToMajor) {
				this.$emit("getxuanke", {
					year: this.xuankeYear,
					areaIDs: this.getAreaIdsStr(),
					schoolTypes: this.getSchTypeStr(),
					Is985: this.Is985 ? 1 : 0,
					Is211: this.Is211 ? 1 : 0,
					IsXiao211: this.IsXiao211 ? 1 : 0,
					IsGraduateSch: this.IsGraduateSch ? 1 : 0,
					levels: this.level,
					foundTypes: this.foundtype.join(','),
					yiliu: this.yiliu,
				});
			} else if (this.xuankeToMajor) {
				var obj = {
					year: this.xuankeYear,
					areaIDs: this.getAreaIdsStr(),
					schoolTypes: this.getSchTypeStr(),
					Is985: this.Is985 ? 1 : 0,
					Is211: this.Is211 ? 1 : 0,
					IsXiao211: this.IsXiao211 ? 1 : 0,
					IsGraduateSch: this.IsGraduateSch ? 1 : 0,
					levels: this.level,
					foundTypes: this.foundtype.join(','),
				}
				if (this.xuanke)
					obj.subjects = this.subjects.join(',')
				if (this.showSchMajor) {
					obj.schname = this.schname
					obj.majorname = this.joinmajornames()
				}

				this.$emit("getxuankeToMajor", obj);
			} else if (this.search && !this.xuanke) {
				this.$emit("getschool", {
					areaIDs: this.getAreaIdsStr(),
					SchType: this.getSchTypeStr(),
					Is985: this.Is985 ? 1 : 0,
					Is211: this.Is211 ? 1 : 0,
					IsXiao211: this.IsXiao211 ? 1 : 0,
					IsGraduateSch: this.IsGraduateSch ? 1 : 0,
					KeyWord: this.keyword,
					Level: this.level,
					yiliu: this.yiliu,
					FoundType: this.foundtype.join(','),
					MajorIDs: this.joinmajorids(),
				});
			}
		},
		// getprovincelist() {
		// 	this.$http.get("Sys/area/getprovincelist", {

		// 	}, true).then((res) => {
		// 		if (res.code == 1) {
		// 			this.provincelist = res.data;
		// 		} else {
		// 			this.$message.error(res.msg);
		// 		}
		// 	});
		// },
		// getschooltypelist() {
		// 	this.$http.get("/dictionary/getschtypelist", {

		// 	}, true).then((res) => {
		// 		if (res.code == 1) {
		// 			this.schooltypelist = res.data;
		// 		} else {
		// 			this.$message.error(res.msg);
		// 		}
		// 	});
		// },
		getYear() {
			this.$http.get("/XuanKe/GetYearsOfNewGK", {

			}, true).then((res) => {
				if (res.code == 1) {
					this.xuankeYears = res.data;
					if (this.xuankeYears.length > 0) {
						this.xuankeYear = this.xuankeYears[0];
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		GetSchoolLevel() {
			this.$http.get("/School/GetSchLevel", {

			}, true).then((res) => {
				if (res.code == 1) {
					this.SchLevel = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		GetSchFoundType() {
			this.$http.get("/School/GetSchFoundType", {

			}, true).then((res) => {
				if (res.code == 1) {
					this.SchFoundType = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		setSchoolLevel(level) {
			if (level && level != undefined) {
				this.yiliu = 0;
				this.level = level;
			}
		},
		setSchoolYiLiu(yiliu) {
			if (yiliu && yiliu != undefined) {
				this.level = "106";
				this.yiliu = yiliu;
			}
		},
		GetSchFileters() {
			return new Promise((resolve, reject) => {
				this.$http.get("/School/GetSchoolFilters", {
					needcitys: "0",
				}, true).then((res) => {
					if (res.code == 1) {
						this.SchFoundType = res.data.FoundType;
						this.SchLevel = res.data.Level;
						this.xuankeYears = res.data.NewGkYeaks;
						this.schooltypelist = res.data.SchType;
						this.schooltypelist.unshift({
							Code: "0",
							Name: "不限"
						});
						this.provincelist = res.data.Provinces;
						this.provincelist.unshift({
							ID: "0",
							PID: "0",
							Name: "不限"
						});
						if (this.xuankeYears && this.xuankeYears.length > 0) {
							this.xuankeYear = this.xuankeYears[0];
							//this.$nextTick(function () {
							//this.getschool();
							//})
						} else {
							//this.$nextTick(function () {
							//this.getschool();
							//})
						}
						resolve(res.code);
					} else {
						this.$message.error(res.msg);
						reject(res.msg);
					}
				});
			});
		},
		initCacheMoBanPiCiID() {
			let obj = this.getDataByCache("moBanPiCiID");
			this.moBanPiCiID = obj;
		},
		initCacheLevel() {
			let obj = this.getDataByCache("level");
			this.level = obj;
		},
		initCacheSchType() {
			let obj = this.getDataByCache("schType");
			if (obj && obj.length > 0) {
				this.SchType = obj;
			}
		},
		initCacheProvince() {
			let obj = this.getDataByCache("province");
			if (obj && obj.length > 0) {
				this.Province = obj;
				if (this.Province.length == 0)
					this.Province.push("0");
			}
		},
		initCacheMajorName() {
			let str = this.getDataByCache("majorName");
			if (str && str != '') {
				this.majorname = str;
			}
		},
		initCacheSchoolName() {
			let str = this.getDataByCache("schName");
			if (str && str != '') {
				this.schname = str;
			}
		},
		initCacheMajors() {
			let obj = this.getDataByCache("majors");
			if (obj && obj.length > 0) {
				this.MajorIDs = obj;
			}
		},
		// 存取 store
		getDataByCache(char) {
			let key = this.PageKey;
			let name = key + char;
			return this.$store.getters.GetSchFilterByKey(name);
		},
		setDataByCache(char, data) {
			let key = this.PageKey;
			let obj = {};
			obj.key = key + char;
			obj.data = data;

			this.$store.commit('SetSchFilterByKey', obj);
			// this.$store.SetSchFilterByKey(key+char,data);
		},
		initCacheFoundtype() {
			let obj = this.getDataByCache("foundtype");
			if (obj && obj.length > 0) {
				this.foundtype = obj;
			}
		},
		initCacheIs985() {
			let obj = this.getDataByCache("is985");
			this.Is985 = obj;
		},
		initCacheIs211() {
			let obj = this.getDataByCache("is211");
			this.Is211 = obj;
		},
		initCacheIsXiao211() {
			let obj = this.getDataByCache("isXiao211");
			this.IsXiao211 = obj;
		},
		initCacheyiliu() {
			let obj = this.getDataByCache("yiliu");
			this.yiliu = obj;
		},
		initCacheSubjects() {
			let obj = this.getDataByCache("subjects");
			if (obj && obj.length > 0)
				this.subjects = obj;
		},
		initCacheXuanKeYear() {
			let obj = this.getDataByCache("xuankeYear");
			// this.xuankeYear = obj;
		},
		initCacheStuScore() {
			let obj = this.getDataByCache("stuScore");
			if (obj)
				this.stuScore = obj;
		},
		initCacheZkType() {
			let obj = this.getDataByCache("ZkType");
			if (obj)
				this.ZkType = obj;
		},
		initCacheData() {
			this.initCacheSubjects();
			this.initCacheMajors();
			this.initCacheSchoolName();
			this.initCacheMajorName();
			this.initCacheProvince();
			this.initCacheSchType();
			this.initCacheLevel();
			this.initCacheMoBanPiCiID();
			this.initCacheFoundtype();
			this.initCacheIs985();
			this.initCacheIs211();
			this.initCacheIsXiao211();
			this.initCacheyiliu();
			this.initCacheXuanKeYear();
			this.initCacheZkType();
		},
		setCacheMoBanPiCiID() {
			this.setDataByCache("moBanPiCiID", this.moBanPiCiID);
		},
		setCacheLevel() {
			this.setDataByCache("level", this.level);
		},
		setCacheSchType() {
			this.setDataByCache("schType", this.SchType);
		},
		setCacheProvince() {
			this.setDataByCache("province", this.Province);
		},
		setCacheMajorName() {
			this.setDataByCache("majorName", this.majorname);
		},
		setCacheSchoolName() {
			this.setDataByCache("schName", this.schname);
		},
		setCacheMajors() {
			this.setDataByCache("majors", this.MajorIDs);
		},
		setCacheFoundtype() {
			this.setDataByCache("foundtype", this.foundtype);
		},
		setCacheIs985() {
			this.setDataByCache("is985", this.Is985);
		},
		setCacheIs211() {
			this.setDataByCache("is211", this.Is211);
		},
		setCacheIsXiao211() {
			this.setDataByCache("isXiao211", this.IsXiao211);
		},
		setCacheyiliu() {
			this.setDataByCache("yiliu", this.yiliu);
		},
		setCacheSubjects() {
			this.setDataByCache("subjects", this.subjects);
		},
		setCacheXuanKeYear() {
			this.setDataByCache("xuankeYear", this.xuankeYear);
		},
		setCacheStuScore(data) {
			this.setDataByCache("stuScore", data);
			this.$store.commit("set_ScoreAndOrder", data);
			this.stuScore = data;
		},
		setCacheZkType(data) {
			this.setDataByCache("ZkType", this.ZkType);
		},
		clearAll() {
			this.moBanPiCiID = '';
			//this.level = '';
			this.SchType = [];
			this.SchType.push("0");
			this.Province = [];
			this.Province.push("0");
			this.majorname = '';
			this.schname = '';
			this.MajorIDs = [];
			this.IsSchTagNoLimit = true;
			this.foundtype = [];
			this.Is985 = false;
			this.Is211 = false;
			this.IsXiao211 = false;
			this.yiliu = false;
			this.subjects = [];
			this.xuankeYear = '';
			this.stuScore = null;
			this.ZkType = [];
			this.by = -1;
			// this.startvalue = '';
			// this.endvalue = '';
			// this.startTemp = '';
			// this.endTemp = '';

			this.$nextTick(function () {
				this.getschool();
			});
		},
		initScore() {
			return new Promise((resolve, reject) => {
				if (this.stuScore && (!this.stuScore.Score || !this.stuScore.LOrder)) {
					this.stuScore = null;
				}
				this.stuScore = this.$store.getters.get_ScoreAndOrder();
				if (this.stuScore) {
					this.MyScore = ['155', '177'].includes(this.MobanPiciModel.PiCi + '') && this.stuScore.Score107 ? this.stuScore.Score107 : this.stuScore.Score;
					this.MyOrder = ['155', '177'].includes(this.MobanPiciModel.PiCi + '') && this.stuScore.LOrder107 ? this.stuScore.LOrder107 : this.stuScore.LOrder;

					this.startvalue = parseInt(this.MyScore) > 0 ? parseInt(this.MyScore) - 15 : '';
					this.endvalue = parseInt(this.MyScore) > 0 ? parseInt(this.MyScore) + 5 : '';
					this.changeScore(1, resolve, reject);
					this.changeScore(2, resolve, reject);
				}
			});
		},
		GetZKTypeList() {
			return new Promise((resolve, reject) => {
				this.$http.get("/Plan/GetZKTypeList", {
					AreaID: this.AreaID
				}, true).then((res) => {
					if (res.code == 1) {
						this.ZKTypeList = res.data;
						resolve(res.code);
					} else {
						this.$message.error(res.msg);
						reject(res.code);
					}
				});
			});
		},
		initFilter() {
			//显示分数时按分数查询否则默认不限
			this.by = this.IsFen == 1 ? -1 : 0;
			this.IsHideScore = this.by == -1;

			this.XuanKeType = this.$store.getters.getXuankeType()
			if (this.MobanPiciModel.ID) {
				this.level = this.MobanPiciModel.ScoreType == '107' ? '107' : '106';
				this.moBanPiCiID = this.MobanPiciModel.ID;
				this.piciEnable = false;
			}
			this.yiliu = this.syiliu;
			this.PageKey = this.$route.name + "_";

			let flagGetZkType = this.GetZKTypeList();
			let flagGetPiCi = this.getPicis();
			let flagGetScore = this.initScore();
			let flagGetFileter = this.GetSchFileters();
			Promise.allSettled([flagGetScore, flagGetZkType, flagGetPiCi, flagGetFileter]).then(results => {
				this.getschool();
			}).catch(error => {
				this.getschool();
			});
		}
	},
	mounted() {
		//使用window.addEventListener来全局监听键盘按键事件。
		window.addEventListener('keydown', this.keyDown);
	},
	created() {
		this.initFilter();
	},
}
</script>

<style scoped="scoped">
.score_span {
	padding: 10px 3px;
	color: #bbb;
	background-color: #f4f4f4;
	font-size: 12px;
}

.major_content .el-button {
	margin-left: 0;
	margin-right: 5px;
	margin-bottom: 5px;
}

.width_content .el-checkbox {
	width: 45px;
}

.content .el-checkbox {
	height: 30px;
}

.filter {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 30px;
}

.type {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 10px;
}

.leftCol {
	text-align: right;
}

.rightCol {
	border-bottom: 1px solid #D4D7DE;
}

.title {
	margin-top: 10px;
	margin-right: 20px;
	color: #000000;
}

.content {
	padding: 10px;
	padding-left: 20px;
}

.noLimit {
	font-weight: bold;
}
</style>