import {
	createApp
} from 'vue'
import App from './App.vue'
var app = createApp(App);
import './assets/font_3666265_36vetkoycf1/iconfont.css'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import './assets/css/common.css'

app.use(ElementPlus, {
	locale: zhCn,
})
import 'element-plus/dist/index.css'
import {
	ElMessage,
	ElMessageBox
} from 'element-plus'
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$confirm = ElMessageBox.confirm
app.config.globalProperties.$alert = ElMessageBox.alert
import router from './router'
app.use(router);

import store from './store/index.js'
app.use(store)
app.config.globalProperties.$store = store


// js通用方法
import api from './api/api.js'
app.config.globalProperties.$api = api

import base from './assets/script/common.js'
import http from './api/http.js'
app.config.globalProperties.$http = http
app.config.globalProperties.$base = base
import util from './util/util.js'
app.config.globalProperties.$util = util
import globle from './util/globle.js'
app.config.globalProperties.$globle = globle

import VueCookies from 'vue-cookies'
app.config.globalProperties.$cookies = VueCookies

import Header from './components/Header.vue'
app.component('Header', Header)
import Footer from './components/Footer.vue'
app.component('Footer', Footer)
import Navi from './components/Navi.vue'
app.component('Navi', Navi)
import HeaderTitle from './components/cp_header_title.vue'
app.component('HeaderTitle', HeaderTitle)
import school from './components/cp_school.vue'
app.component('school', school)
import schoolFilter from './components/cp_school_filter.vue'
app.component('schoolFilter', schoolFilter)
import majorFilter from './components/cp_major_filter.vue'
app.component('majorFilter', majorFilter)
import city from './components/cp_city.vue'
app.component('city', city)
import recordmajor from './components/cp_record_major.vue'
app.component('recordmajor', recordmajor)
import recordschool from './components/cp_record_school.vue'
app.component('recordschool', recordschool)
import scoretype1 from './components/cp_score_type1.vue'
app.component('scoretype1', scoretype1)
import scoretype2 from './components/cp_score_type2.vue'
app.component('scoretype2', scoretype2)
import scoretype3 from './components/cp_score_type3.vue'
app.component('scoretype3', scoretype3)
import majorSelect from './components/cp_major_select.vue'
app.component('majorSelect', majorSelect)
import schoolTag from './components/cp_school_tag.vue'
app.component('schoolTag', schoolTag)
import fanganMajor from './components/cp_fangan_major.vue'
app.component('fanganMajor', fanganMajor)
import login from './components/cp_login.vue'
app.component('login', login)
import favrate from "./components/cp_fav.vue"
app.component('favrate', favrate)
import scoreShower from './components/Plan/cp_ScoreShower.vue'
app.component('scoreShower', scoreShower)
import verify from "./components/cp_loginVerify.vue"
app.component('verify', verify)

import jieDuNumber from "./components/tag/cp_number.vue"
app.component('jieDuNumber', jieDuNumber)

import compareIcon from "./components/compare/cp_icon.vue"
app.component('compareIcon', compareIcon)
import compareTool from "./components/compare/cp_toolItem.vue"
app.component('compareTool', compareTool)


import SlideVerify from './lib/verify/index'
app.use(SlideVerify)

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
app.use(VueQuillEditor)


import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
app.config.globalProperties.$ElementPlusIconsVue = ElementPlusIconsVue

import Vuelazyload from 'vue-lazyload'
app.use(Vuelazyload, {
       error: require('../public/imgs/gby.png'),
       loading: require('../public/imgs/gby.png')
})
// 时间处理
import Moment from 'moment'
import 'moment/dist/locale/zh-cn'
Moment.locale('zh-cn')
app.config.globalProperties.$moment = Moment
app.mount("#app");

